<template>

  <el-table  :data="tableData" v-loading="loading"
    :header-cell-style="{ background: '#ffffff', color: '#909399' }" :size="tableSize" :height="tableHeight"
    style="width: 100%;" @selection-change="handleSelectionChange">

    <!--<el-table-column type="index" label="序号" width="60" :index="indexMethod" v-if="!isFixed" />-->

    <slot></slot>

  </el-table>
</template>

<script>
  let flag = null;

  export default {
    name: "TableModel",
    components: {},
    data() {
      return {
        tableHeight: 300,
        multipleSelection: [],
      }
    },
    props: {
      // 表格数据
      tableData: {
        type: Array,
        default: []
      },
      // 是否有筛选条件 用于计算表格的高度
      isShowSelect: {
        type: Boolean,
        default: true
      },
      // 表格加载时的 loading
      loading: {
        type: Boolean,
        default: false
      },
      // 表格的 size
      tableSize: {
        type: String,
        default: "mini"
      },
      // 表格的 size
      height: {
        type: Number,
        default: 0
      },
      // 页面是否有 Tabs 标签选择
      isShowTabs: {
        type: Boolean,
        default: false
      },
      isFixed: {
        type: Boolean,
        default: false
      },
	    // 表格超出的高度
	    goBeyondHeight: {
	      type: Number,
	      default: 0
	    }
    },

    created() {
      this.setTableHeight();
    },

    mounted() {
      let that = this;
      window.onresize = function () {
        flag && clearTimeout(flag);
        flag = setTimeout(() => {
          that.setTableHeight();
          flag = null;
        }, 300);
      }
    },
    beforeDestroy() {
      window.onresize = null;
    },


    methods: {
      indexMethod(index) {
        return index + 1;
      },


      setTableHeight() {
        if (this.height) {
          this.tableHeight = this.height
          return
        }
        let header = 90;  // 头部高度(50px) + 面包屑(40px)
        let select = this.isShowSelect ? 80 : 0;  // 筛选栏高度 + 上下margin
        let tabsH = this.isShowTabs ? 40 : 0;  // tabs标签选择的高度
        let tableTitle;  // 表格头部高度
        if (this.tableSize === "mini") {
          tableTitle = 37;
        } else if (this.tableSize === "medium") {
          tableTitle = 50;
        }
        let pageH = 45;  // 页码高度 + 上下margin

        let h = window.innerHeight;

        this.tableHeight = h - header - select - tabsH - tableTitle - pageH - this.goBeyondHeight;
        // console.log('tableHeight',this.tableHeight);

      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
        let ids = this.multipleSelection.map((item) => {
          return item.id
        })
        let names = this.multipleSelection.map(item=>{
          return item.name
        })
        this.$emit('handleSelectionChange', ids,names)
      },
    }
  }
</script>