<template>
    <div class="outbox">
        <div class="mainbox">
            <div class="topbox">
            <!-- 证件号码 -->
                <el-input
                v-model="params.mainMessage"
                placeholder="请输入居民身份证号码"
                class="query-width"
                size="medium"
                />
                <el-button class="checkBtn" type="primary" size="medium" @click="queryData('btn')">查询</el-button>
            </div>
            
        </div>
        <div class="bottombox">
            <div class="" v-if="tableData.length">
                <div class="condition-filtrate-box">
                    <div class="left-item">
                        <el-date-picker
                        class="timepick"
                        v-model="pickerTime"
                        size="medium"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @change="handelRiqi" />

                        <el-select
                        class="select-width"
                        size="medium"
                        v-model="operationUser"
                        placeholder="操作人员"
                        @change="handelRenyuan">
                        <el-option v-for="item in isolationList" :key="item.userId" :label="item.userName" :value="item.userId" />
                        </el-select>

                        <el-select
                        class="select-width"
                        size="medium"
                        v-model="liebiaoId"
                        placeholder="功能模块"
                        @change="handleMoudle">
                        <el-option v-for="item in liebiaoData" :key="item.name" :label="item.name" :value="item.code" />
                        </el-select>
                    </div>
                </div>

                <TableModel
                :is-show-select="true"
                :is-show-tabs="false"
                :loading="loading"
                :table-data="tableData"
                table-size="medium">
                <el-table-column prop="operationName" label="操作人" width="80" />
                <el-table-column prop="crtTime" label="操作时间" width="200"/>
                <el-table-column prop="operationModule" label="操作板块" width="180"/>
                <el-table-column prop="operationContent" label="操作内容" />
                
                </TableModel>

                <div class="content-page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNumber"
                    :page-size="pageSize"
                    :total="total"
                    :page-sizes="[10, 20, 30, 50, 100]"
                    layout="total, sizes, prev, pager, next, jumper"
                ></el-pagination>
                </div>
            </div>
            <div v-if="!tableData.length" style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;">
                <div style="display:flex;flex-direction: column;justify-content:center;align-items: center;">
                    <img style="width:46px;height:48px;" src="../../../static/imageCs/暂无.png" alt="">
                    <div style="color:rgb(138,150,163);margin-top: 30px;">请先输入居民身份证查询数据</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableModel from '../../components/TableModel';
import { getBusinesslog,getModuleList,getOperationUser } from '@/api/SystemManagement/index';
import { mapState } from 'vuex';
export default {
    // name: "OperationLog",
    components: { TableModel },
    data(){
        return{
            params:{
                mainMessage:"",
            },
            userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
            roles: JSON.parse(localStorage.getItem("roles")),
            tableData: [], //表单数据
            pageNumber: 1,
            pageSize: 100,
            total: 0,
            loading: false,
            isolationPointId: "",
            liebiaoId:'',
            pickerTime: [],
            isolationList:[],
            liebiaoData:[],
            level:'',
            moduleName:'',
            operationUser:''
        }
    },
    created() {
    // this.getModuleList()
    // this.operationRecordList();
  },

  methods: {
        // 查询
        queryData(){
            if(this.params.mainMessage == ''){
                this.$message({
                    message: '查询内容不能为空！',
                    type: 'warning'
                });
                return
            }
            this.operationRecordList()
            this.getOperationUser()
            this.getModuleList()
        },
        // 获取人员列表
        getOperationUser(){
            let params = {
                cardNo:this.params.mainMessage,
                hospitalId:this.userdoctor.hospitalId
            }
            getOperationUser({ params }).then(res=>{
                this.isolationList = res.data.data
            })
        },
        // 获取模块列表
        getModuleList(){
            let params = {
                cardNo:this.params.mainMessage,
                hospitalId:this.userdoctor.hospitalId
            };
            getModuleList({ params }).then(res => {
                this.liebiaoData = res.data.data
            })
        },
        // 获取列表数据
        operationRecordList() {
            if(this.pickerTime == null){
                this.pickerTime = ['','']
            }
        let params = {
            startTime:this.pickerTime[0],
            endTime:this.pickerTime[1],
            moduleLevel:this.level,
            moduleName:this.moduleName,
            operationUser:this.operationUser,
            cardNo:this.params.mainMessage,
            pageSize:this.pageSize,
            totalPage:this.total,
            pageNumber:this.pageNumber
        };

            getBusinesslog({ params }).then(res => {
                let { code, data, msg } = res.data;
                if (code == 200) {
                this.tableData = data.list;
                this.total = data.totalRow;
                if(!this.tableData.length){
                    this.pickerTime = ['','']
                    this.level='',
                    this.moduleName='',
                    this.operationUser=''
                }
                } else {
                this.$message.error(msg)
                }
            })
        },
        // 选择日期
        handelRiqi(){
            this.operationRecordList()
        },
        // 选择模块
        handleMoudle(row){
            console.log(row);
            let item = {}
            this.liebiaoData.forEach(res=>{
                if(res.code == row){
                    item = res
                }
            })
            this.level = item.level
            this.moduleName = item.name
            this.operationRecordList()
            console.log(item);
        },
        // 选择人员
        handelRenyuan(row){
            this.operationRecordList()
        },

        handleSizeChange(val) {
        this.pageSize = val;
        this.operationRecordList();
        },

        handleCurrentChange(val) {
        this.pageNumber = val;
        this.operationRecordList();
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../static/css/condition-filtrate.scss";

.content-page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}

.el-table >>> td {
  padding: 6px 0;
}
.outbox{
    display: flex;
    flex-direction: column;

}
.mainbox{
    width: 100%;
    height: 50px;
    // background: pink;
    margin: 100px 0 0 0;
}
.topbox{
    width: 500px;
    height: 50px;
    // background: red;
    margin: 0 auto;
    display: flex;
    .checkBtn{
        height: 36px;
        margin: 0 0 0 30px;
    }
}
.bottombox{
    flex: 1;
    // background: orange;
    margin-top: -90px;
}
.timepick{
    margin-right: 20px;
}
</style>